import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './NewsBanner.scss'
const NewsBanner = (props) => {
    return (
        <section className={`news-banner ${props.choose_collection}`}>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h4>{props.title}</h4>
                            {parse(props.description)}
                            {props.choose_collection === "reviews" && <div className="elfsight-app-4e696d23-bc73-4f15-9d6e-059b0557a83d review_widget"></div>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default NewsBanner
