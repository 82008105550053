
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function BranchStructuredData({ description, pageurl }) {
  const site_url = process.env.GATSBY_SITE_URL; 
  var ldJson = {
    "@context": "http://www.schema.org",
    "@type": "RealEstateAgent",
    "name": "Arabian Estates Real Estate Dubai",
    "url": `${site_url}/contact-arabian-estates/`,
    "logo": "https://ggfx-arabianestates.s3.eu-west-2.amazonaws.com/i.prod/logo_black_67f0206787.png",
    "description": `${description}`,
    "address": {
    "@type": "PostalAddress",
    "streetAddress": "11 C07, I Rise Tower",
    "addressLocality": "Barsha Heights",
    "addressRegion": "Dubai",
    "addressCountry": "United Arab Emirates"
    },
    "hasMap" : "https://www.google.com/maps/place/Dubai+-+United+Arab+Emirates/@15.9676487,48.1479687,4z/data=!4m19!1m12!4m11!1m3!2m2!1d55.2671095!2d24.9720988!1m6!1m2!1s0x3e5f6dda31c04aa5:0x643505bb9074f0f!2sArabian+Estates+Real+Estate+Agents+in+Dubai!2m2!1d55.1424259!2d25.0721576!3m5!1s0x3e5f43496ad9c645:0x74ced0bf2b0029e9!8m2!3d24.9821547!4d55.402868!16s%2Fm%2F012nzlyj",
    "sameAs" : "https://www.google.com/maps/place/Dubai+-+United+Arab+Emirates/@15.9676487,48.1479687,4z/data=!4m19!1m12!4m11!1m3!2m2!1d55.2671095!2d24.9720988!1m6!1m2!1s0x3e5f6dda31c04aa5:0x643505bb9074f0f!2sArabian+Estates+Real+Estate+Agents+in+Dubai!2m2!1d55.1424259!2d25.0721576!3m5!1s0x3e5f43496ad9c645:0x74ced0bf2b0029e9!8m2!3d24.9821547!4d55.402868!16s%2Fm%2F012nzlyj",
    "telephone": "+971 5 641 44147",
    "contactPoint": 	{
    "@type": "ContactPoint",
    "contactType": "Sales",
    "telephone": "+971 4 324 3137"
    }
    }
    
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default BranchStructuredData
