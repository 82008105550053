import { Link } from 'gatsby'
import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import './PageNav.scss';

const PageNav = (props) => {
    
    const data = useStaticQuery(graphql`
    query {
		glstrapi {
            menus {
              slug
              link_type
              external_link
              parent {
                link_type
                external_link
                slug
                label
                parent {
                  external_link
                  slug
                  label
                  link_type
                  parent {
                    slug
                    label
                    link_type
                    external_link
                  }
                }
              }
              _id
              label
            }
        }
    }
  `);

    let PageURL = data.glstrapi?.menus?.filter(item => item._id === props.menu).pop();


    const parentUrl=`/${PageURL.parent.parent.slug}/${PageURL.parent.slug}/`
    return (
        <section className='page-nav p-0'>
            <ul>
                <li>
                    <Link to="/" className="nav-links">Home</Link>
                </li>
                {PageURL.parent &&
                <li>
                    <Link to={parentUrl} className="nav-links">{PageURL.parent.label}</Link>
                </li>
                }
                <li>
                    <div to="/" className="nav-links">{props.page}</div>
                </li>
            </ul>
        </section>
    )
}

export default PageNav