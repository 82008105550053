
import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useStaticQuery, graphql } from "gatsby"
import ContactForm from "../forms/list-a-property"


const ListAProperty = (props) => {
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    return (
        <section className={scroll ? "banner banner--fixed" : "banner"} style={{ backgroundImage: `url(${props?.banner_image?.url})` }}>
            <div className="bg-overlay">
                <Container className="content">
                    <Row>
                        <Col md={8}>
                            <div className="form-block">
                                {parse(props.content)}
                            </div>
                            <div className="contact-form">
                                <div className="head">
                                    {/* {parse(props.add_components[0].contact_form_intro)} */}
                                </div>
                                <ContactForm />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>

    )
}

export default ListAProperty