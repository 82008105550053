import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { AnimationOnScroll } from 'react-animation-on-scroll'; 

 

const ReviewsSection = () => {
  

    return (
        <section className='reviews-wrap'>
            <Container>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div class="elfsight-app-0be52df5-a496-490d-8dac-1f4591b38245"></div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default ReviewsSection