import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parse from "html-react-parser"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Services.scss';

const Services = (props) => {
    var add_class = ""
    if (props.module.add_new_stats_or_service.length == 4) {
        add_class = "four-cols"
    }
    return (
        <section className='services-wrap white-bg'>
            <Container>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className={`content-wrap ${add_class}`}>
                                {
                                    props.module.add_new_stats_or_service && props.module.add_new_stats_or_service.map((module, i) => {
                                        return (
                                            <div className='services-content'>
                                                <i className={`icon-${module.icon_class_name} flex-shrink-0`}></i>
                                                <div className='service-text'>
                                                    <h2>{module.title}</h2>
                                                    {parse(module.description)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default Services