import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import ManagerImg from "../../images/manager-img.png"
import '../SearchData/SearchData.scss'
import '../SearchSelect/SearchSelect.scss';
import ManagerPanel from '../ManagerPanel/AreaGuide'

const SearchData = () => {

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                areaGuides(where: {publish: true}) {
                    id
                    title
                    slug
                    tile_image {
                      alternativeText
                      url
                    }
                    imagetransforms
                }
            }
        }
    `);

    const allguides = data.glstrapi.areaGuides;

    return (
        <section className={"search-banner our-team-lisiting area-guides-listing"}>
            <Container>
                <Row className='result-column management-wrap team-result'>
                    {
                        allguides?.map((item, index) => (
                            <Col md={6} lg={3}>
                                <ManagerPanel item={item} key={index} />
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </section>
    )
}

export default SearchData