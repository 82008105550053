import React, { useState } from 'react'
import Select from 'react-select';
import LocationSelect from '../LocationSelect/LocationSelect'
import './BannerSelect.scss'
import { navigate } from "@reach/router";
const BannerSelect = (props) => {
  const [state, setState] = useState(48);
  const [value, setValue] = useState("Buy");
  const tabbingData = props.tabbingData;
  const handleChange = (e) => {
    if(e.value === "list a property") {
      navigate('/list-a-property')
    }
    setState(e.value.length * 26)
    setValue(e.value)
  }
  let tabOptions = []
  Object.keys(tabbingData)?.forEach((item, index) => {
    tabOptions.push({ value: item, label: item })
  })
  return (
    <div className='select-box'>
      <div className='select-tabbing'>
        <div className='select-wrap'>
          {/* <select className='banner-select' onChange={handleChange} style={{ width: `${state}px` }}>
            {Object.keys(tabbingData).map((data, index) => {
              return <option key={index} value={data}>{data}</option>
            })
            }
          </select> */}
          <Select className="react-select" classNamePrefix="react-select" options={tabOptions} defaultValue={tabOptions[0]} onChange={handleChange} isSearchable={true} />
        </div>
      </div>
      <LocationSelect landingpage={props?.landingpage} tabbingData={tabbingData[value]} title={value} />
    </div>
  )
}

export default BannerSelect;