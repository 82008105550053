import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
import { Container, Row, Col, Form } from "react-bootstrap"
import Loadable from "@loadable/component"


import Banner from "./Banner/Banner";
import LandingBanner from "./Banner/LandingBanner";
import GuideBanner from "./Banner/GuideBanner";
import Services from "./Services/Services";
import ServicesPlain from "./Services/ServicesPlain";
import AboutSection from "./AboutSection/AboutSection";
import RatingSection from "./RatingSection/RatingSection";
import VideosSlider from "./VideosSlider/VideosSlider";
import Properties from "./Properties/Properties";
import FindProperty from "./FindProperty/FindProperty";
import BrowseProperty from "./BrowseProperty/BrowseProperty";


import NewsBanner from "./NewsBanner/NewsBanner";
import AreaBanner from "./AreaBanner/AreaBanner";
import NewsTabbing from "./NewsTabbing/NewsTabbing";
import Team from "./Team/Team";
import AreaGuides from "./AreaGuides/AreaGuides";
import QuickTiles from "./AreaGuides/QuickTiles";
import Reviews from "./RatingSection/Reviews";
import TeamSlide from "./Team/TeamSlide";
import StaticComponent from "./StaticComponent/StaticComponent";
import FormComponent from "./FormComponent/FormComponent";
import ListAProperty from "./ListAProperty/ListAProperty";
import StaticComponentImageContent from "./StaticComponent/StaticComponentImageContent";
import StaticComponentImageContentBlue from "./StaticComponent/StaticComponentImageContentBlue";
import FAQModule from "./FAQModule/FAQModule";
import GuidePoints from "./GuidePoints/GuidePoints";
import BranchStructuredData from "./Seo/BranchStructuredData";
import HomeStructuredData from "./Seo/HomeStructuredData";
import AboutStructuredData from "./Seo/AboutStructuredData";

const Modules = (props) => {
    return (
        <React.Fragment>

            {
                props?.title === "Home" ?
                    <HomeStructuredData />
                    :
                    props?.title === "About Us" ?

                        <AboutStructuredData />
                        : props?.title === "Contact" ?
                            <BranchStructuredData
                                description={props?.seo_meta?.description}
                            />
                            : null
            }

            {props.layout == "homepage_template" && props.banner_image ?

                <Banner {...props} /> : props.layout == "landing_template" && props.banner_image && (props.show_search || props.banner_right_content) ? <LandingBanner {...props} /> : props.layout == "landing_template" && props.banner_image && props.show_search === false ? <AreaBanner {...props} /> : ''}

            {props.layout == "guide_template" ?
                <GuideBanner {...props} /> : ''
            }

            {props.layout == "static_template" && props.alias != "news-videos" ? <StaticComponent {...props} /> : ''}

            {/* {props.layout == "form_template" ? <FormComponent {...props} />: ''} */}

            {
                props.add_components && props.add_components.map((module, i) => {
                    return (
                        <>

                            {module.__typename === "GLSTRAPI_ComponentComponentsAddVideoReview" &&
                                <VideosSlider {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsListAPropertyForm" && props.layout === "form_template" &&
                                <ListAProperty {...props} {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsContactPageInfo" && props.layout === "form_template" &&

                                <FormComponent {...props} {...module} />

                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsIntroSection" &&
                                <AboutSection {...props} {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsShowCustomerReviews" &&
                                <RatingSection />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsFullWidthTwoColumnBlock" &&
                                <Properties {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsTextBlockWithImage" &&
                                <>
                                    {module.layout_type == "left_align_image_blue_background" ?
                                        <StaticComponentImageContentBlue {...props} {...module} /> :
                                        <StaticComponentImageContent {...props} {...module} />}
                                </>
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" &&
                                <FindProperty {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsShowMeetTeam" &&
                                <TeamSlide {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsShowModule" && module.module &&
                                <> {module.module.module_type == "text_with_image" ? <BrowseProperty {...module} /> : module.module.module_type == "stats_or_services" ? <Services {...module} /> : module.module.module_type == "stats_white_background" ? <ServicesPlain {...module} /> : ''}
                                </>
                            }

                            {module.__typename == "GLSTRAPI_ComponentComponentsSelectCollection" &&
                                <>
                                    {module.title || module.decription ?
                                        <NewsBanner {...module} /> : ''}
                                    {module.choose_collection === "blogs" ?
                                        <NewsTabbing /> : module.choose_collection === "teams" ?
                                            <Team /> : module.choose_collection === "area_guides" ?
                                                <AreaGuides /> : module.choose_collection === "reviews" ? <Reviews /> : ''
                                    }
                                </>
                            }

                            {module.__typename == "GLSTRAPI_ComponentComponentsFaq" &&
                                <FAQModule {...module} />
                            }

                            {module.__typename == "GLSTRAPI_ComponentComponentsContentWithPoints" &&
                                <GuidePoints {...module} layout={props.layout} />
                            }

                            {module.__typename == "GLSTRAPI_ComponentComponentsQuickLinks" &&
                                <QuickTiles {...module} />
                            }

                        </>
                    )
                })
            }

        </React.Fragment>
    )
}
export default Modules
