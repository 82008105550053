
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function HomeStructuredData({ description, pageurl }) {
  const site_url = process.env.GATSBY_SITE_URL; 
  var ldJson = {
    "@context": "http://schema.org",
    "@type": "RealEstateAgent",
    "@id": "https://www.arabianestates.ae/#RealEstateAgent",
    "url": "https://www.arabianestates.ae/",
    "name": "Dubai Real Estate Brokers | Real Estate Agents in Dubai | Arabian Estates",
    "description": "Buy, rent, sell or invest in Dubai properties with Arabian Estates, one of the top real estate agents in Dubai. Contact our Dubai real estate brokers today.",
    "logo":{
    "@type":"ImageObject",
    "url":"https://ggfx-arabianestates.s3.eu-west-2.amazonaws.com/i.prod/logo_black_67f0206787.png"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "bestRating": "5",
      "worstRating": "1",
      "reviewCount": "81"
    },
    "sameAs" : ["https://www.facebook.com/arabianestates", "https://www.instagram.com/arabianestates/", "https://www.linkedin.com/company/arabian-estates/"],
    "email": "info@arabianestates.ae",
    "telephone": "+97143243137",
    "priceRange": "AED",
    "hasMap": "https://maps.app.goo.gl/v8xfG1LfQ9s1BKi9A",
    "address": {
    "@type": "PostalAddress",
    "streetAddress": "11 C07, I Rise Tower",
    "addressLocality": "Barsha Heights",
    "addressRegion": "Dubai",
    "postalCode": "",
    "addressCountry": "UAE"
    },
    "openingHours":["Monday-Friday 09:00 am-18:00 pm"],
    "location": {
    "@type": "Place",
    "geo": {
    "@type": "GeoCoordinates",
    "latitude": "25.106807552648306",
    "longitude": "55.22625511676532", 
    }
    },
    "parentOrganization": {
    "@type": "Organization",
    "@id": "https://www.arabianestates.ae/#Organization",
    "url":"https://www.arabianestates.ae/",
    "name":"Arabian Estates",
    "alternateName":"Arabian Estates Dubai Estate Agents",
    "foundingDate": "9th March 2021"}
  }
    
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default HomeStructuredData
