import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Select from "react-select"
import { AllNews } from "../../common/queries/common_use_query"
import "./NewsTabbing.scss"
import "../NewsSelect/NewsSelect.scss"
import NewsTabbingCard from "../NewsTabbingCard/NewsTabbingCard"
import Pagination from "../Pagination/Pagination"
import usePagination from "../../hooks/usePagination"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { graphql, useStaticQuery } from "gatsby"

const allCategoryOption = {
  label: "All News",
  value: "all",
}
const allCategory = [
  { value: "Sales", label: "Sales" },
  { value: "Lettings", label: "Lettings" },
  { value: "Dubai", label: "Dubai" },
  { value: "Company", label: "Company" },
]

const NewsTabbing = () => {
  const data = useStaticQuery(graphql`
    query GetAllNews {
      glstrapi {
        blogs(sort: "createdAt:desc") {
          title
          id
          slug
          tile_image {
            url
            alternativeText
          }
          video_url
          short_description
          post_on
          category
          publish
          imagetransforms
        }
      }
    }
  `)

  const allNews =
    data.glstrapi.blogs && data.glstrapi.blogs.length > 0 && data.glstrapi.blogs

  const [selectedNews, setSelectedNews] = useState(allNews)
  const [selectedOption, setSelectedOption] = useState(allCategoryOption)

  const { isMobile, isTablet } = useDeviceMedia()

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedNews])

  // Category tab logic
  const selectOptions = [
    allCategoryOption,
    ...allCategory
      .filter(data => {
        let hasCategory = false
        allNews?.forEach(element => {
          if (element?.category === data?.value) {
            hasCategory = true
          }
        })
        return hasCategory
      })
      .map(category => {
        return {
          label: category.label,
          value: category.value,
        }
      }),
  ]
  // Category tab logic

  const handleTabChange = option => {
    setSelectedOption(option)
    if (option.value === "all") {
      setSelectedNews(allNews)
      return
    }
    const filteredNews = allNews.filter(news => news.category === option.value)
    setSelectedNews(filteredNews)
  }

  // for pagination
  const itemsPerPage = isMobile ? 8 : isTablet ? 5 : 5
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedNews,
    itemsPerPage,
  })
  // for pagination

  return (
    <>
      <section className="news-tabbing">
        <Container>
          <Row>
            <Col>
              <div className="select-boxes d-block d-md-none">
                <div className="select-tabbing">
                  <div className="select-wrap">
                    <Select
                      options={selectOptions}
                      isSearchable={false}
                      className={"react-select"}
                      classNamePrefix={"react-select"}
                      value={selectedOption}
                      onChange={handleTabChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-md-block">
                <ul className="nav nav-tabs d-md-flex d-none">
                  {selectOptions.map(data => {
                    return (
                      <li className="nav-item">
                        <button
                          type="button"
                          className={`nav-link ${
                            selectedOption.value == data.value ? "active" : ""
                          }`}
                          onClick={() => handleTabChange(data)}
                        >
                          {data.label}
                        </button>
                      </li>
                    )
                  })}
                </ul>
                {currentItems.map((item, index) => {
                  return <NewsTabbingCard key={index} item={item} />
                })}
              </div>
            </Col>
          </Row>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={selectedNews.length}
            setCurrentPage={setCurrentPage}
            topValue={120}
          />
        </Container>
      </section>
    </>
  )
}

export default NewsTabbing
