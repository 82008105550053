import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import ManagerImg from "../../images/manager-img.png"
import '../SearchData/SearchData.scss'
import '../SearchSelect/SearchSelect.scss';
import ManagerPanelTiles from '../ManagerPanel/ManagerPanelTiles'

const QuickTiles = (props) => {


    const allguides = props.Add_Link;

    return (
        <section className={"search-banner our-team-lisiting area-guides-listing quick-links-module"}>
            <Container>
                <Row className='result-column management-wrap team-result'>
                    <h2>{props.Title}</h2>
                    {
                        allguides?.map((item, index) => (
                            <Col md={6} lg={3}>
                                <ManagerPanelTiles item={item} key={index} />
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </section>
    )
}

export default QuickTiles