import React, { useState } from 'react'
import './NewsTabbingCard.scss'
import moment from "moment";
import PlayVideo from "../Play/PlayVideo";
import { Link } from "gatsby"

import { NewsBaseURL } from "../../common/site/utils"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const NewsTabbingCard = (props) => {
  const tabbingData = props.tabbingData;
  const [read, setRead] = useState(false);

  let [isPlay, setPlay] = React.useState(false);

  
  let processedImages = JSON.stringify({});
  if (props.item.imagetransforms?.Tile_image_Transforms) {
    processedImages = props.item.imagetransforms.Tile_image_Transforms;
  }

  return (
    <>
      <div className='tabbing-box' key={props?.item?.tile_image?.url}>
        <Link to={`${NewsBaseURL}${props.item.slug}/`}>
          <div className='video-thumbnail'>
          <ImageTransform imagesources={props.item.tile_image?.url} renderer="srcSet" imagename="blog.Tile_image.tile" attr={{ alt: `${props.item.title} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={props.item.id} format="source" />

            {/* <img src={props.item.tile_image.url} alt="video=img" /> */}
            {props.item.video_url &&
              <PlayVideo url={props.item.video_url} />
            }
          </div>
        </Link>
        <div className={read ? 'box-content' : 'box-content non-active'}>
          <Link to={`${NewsBaseURL}${props.item.slug}/`}><h3>{props.item.title}</h3></Link>
          <span className='date-article'>{moment(props.item.post_on).format("DD MMMM YYYY")}</span>
          <p>{props.item.short_description}</p>
          <Link to={`${NewsBaseURL}${props.item.slug}/`} className="read-article">Read Article</Link>
        </div>
      </div>
    </>
  )
}

export default NewsTabbingCard