import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parse from "html-react-parser"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Services.scss';
import { useStaticQuery, graphql } from 'gatsby';

const Services = (props) => {
    var add_class = ""
    if (props.module.add_new_stats_or_service.length == 4) {
        add_class = "four-cols"
    }

    const data = useStaticQuery(graphql`
        query MyQuery {
            glstrapi {
                propertyStats(publicationState: PREVIEW) {
                    lettingsCount
                    salesCount
                    shortletsCount
                }
            }
        }
    `)

    const propertiesCount = data.glstrapi.propertyStats[0]

    const landingStatsArray = ["Sale Stats" , "Let Stats"]
    let isLandingStat = landingStatsArray.includes(props?.module?.section_name)
    let serviceMainTitle = props?.module?.add_new_text_with_image_module?.text_block?.title
    
    return (
        <section className='services-wrap'>
            <Container>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className={`content-wrap ${add_class}`}>
                                {serviceMainTitle &&
                                <h2>{serviceMainTitle}</h2>}
                                {
                                    props.module.add_new_stats_or_service && props.module.add_new_stats_or_service.map((module, i) => {
                                        return (
                                            <div className='services-content'>
                                                <i className={`icon-${module.icon_class_name} flex-shrink-0`}></i>
                                                <div className='service-text'>
                                                    {module.properties_count == "sales" ? <h4>{propertiesCount.salesCount}</h4> : module.properties_count == "lettings" ? <h4>{propertiesCount?.lettingsCount}</h4> :
                                                    isLandingStat ? <h4>{module.title}</h4> : <h3>{module.title}</h3> }
                                                    {parse(module.description)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default Services