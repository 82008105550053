import React, { useState, useEffect } from "react";
import { Link } from 'gatsby'
import { Col, Container, Row } from "react-bootstrap";
import ManagerImg from "../../images/manager-img.png"
import './Team.scss';
import ManagerPanel from '../ManagerPanel/ManagerPanel'
import Slider from 'react-slick'
import  { TeamBaseURL } from "../../common/site/utils"

const TeamSlider = (props) => {
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <section className={"search-banner our-team-lisiting team-list-slider-main"}>
            <Container>
                <Row className='result-column management-wrap team-result'>
                    <div className="head">
                        <Row>
                            <Col xs={6}>
                                <h2>{props.title}</h2>
                            </Col>
                            <Col xs={6}>
                                <div className="link">
                                    <Link to={TeamBaseURL}>Meet the full team</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Slider {...settings}>
                    {
                        props.select_peoples?.map((item, index) => (
                            // <Col md={6} lg={3}>
                                <ManagerPanel item={item} key={index} />
                            // </Col>
                        ))
                    }
                    </Slider>
                </Row>
            </Container>
        </section>
    )
}

export default TeamSlider