import React from 'react'
import PageNav from '../PageNav/PageNav'
import parse from "html-react-parser"
import { getSrc } from "gatsby-plugin-image"
import Review from "../../images/google-reviews.svg"
import './AreaBanner.scss'
import { Container, Row, Col } from 'react-bootstrap'
import ReviewBadge from '../ReviewBadge/ReviewBadge'

const AreaBanner = (props) => {

  const src = getSrc(props?.banner_image?.url_sharp.childImageSharp)

  return (  
    <div className={`area-banner ${props.show_reviews ? "review-show" : ''}`} style={{ backgroundImage: `url(${src})` }}>
      {/* <PageNav navList={["Home", "About", "Area Guides", "Downtown Dubai Area Guide"]} /> */}
      <div className='bg-overlay'></div>
        <Container>
          <Row>
            <Col>
              <div className='content'>
                {parse(props.banner_content)}
                {props.show_reviews &&
                  <div className="google-review">  
                    <ReviewBadge />
                  </div>
                }
              </div>
            </Col>
          </Row>
        </Container>
      
    </div>
  )
}

export default AreaBanner