import { Link } from 'gatsby'
import React from 'react'
import '../ManagerPanel/ManagerPanel.scss'
import ManagerImg from "../../images/manager-img.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { TeamBaseURL } from "../../common/site/utils"
import ModalTiny from "react-bootstrap/Modal"
import IndividualContact from "../forms/individual-contact-form"
import { useLocation } from "@reach/router"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const ManagerPanel = (props) => {
  const item = props.item;
  const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
  const [person, setPerson] = React.useState({});
  const openSendfrndformModal = (itemData) => {
    setSendfrndformOpen(true);
    setPerson(itemData)
  }
  const closeSendfrndformModal = () => {
    setSendfrndformOpen(false);
  }
  let { pathname } = useLocation();
  let processedImages = JSON.stringify({});
  if (item.imagetransforms?.Tile_image_Transforms) {
    processedImages = item.imagetransforms.Tile_image_Transforms;
  }

  const ImageRender = ({ image_url, node, processedImages }) => {
    return (
      // <ShowProcessedImage images={image_url} attr={{ className: "mb-0 img-fluid", alt: "" }} transforms={imageConfig.property.images.searchResults.sizes} />
      // <img src={image_url} alt="" />
      <ImageTransform imagesources={image_url} renderer="srcSet" imagename="teams.Tile_image.tile" attr={{ alt: `${node.designation} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={node.id} format="source" />
    )
  }


  return (
    <div className='property-card manager-data'>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <Link to={`${TeamBaseURL}${item.slug}/`}>
          <div className='property-img'>
            {item.tile_image ?
              <ImageRender image_url={item.tile_image?.url} node={item} processedImages={processedImages} />
              // <ImageTransform imagesources={item.tile_image?.url} renderer="srcSet" imagename="teams.Tile_image.tile" attr={{ alt: '', class: '' }} imagetransformresult={processedImages} id={item.id} format="source" />
              :
              <img src={ManagerImg} alt="card-img" />}
          </div>
        </Link>
        <div className='property-content'>
          <Link to={`${TeamBaseURL}${item.slug}/`}><h4>{item.name}</h4></Link>
          <p>{item.designation}</p>
          <div className='manager-details'>
            {item.mobile_no &&
              <React.Fragment><a href={`tel:${item.mobile_no}`} className='view-details phone'>{item.mobile_no}</a>/</React.Fragment>
            }
            {item.email &&
              <React.Fragment><a href="javascript:void(0)" onClick={() => { openSendfrndformModal(item) }} className='view-details'>Email</a>/</React.Fragment>
            }
            <Link to={`${TeamBaseURL}${item.slug}/`} className='view-details'>Bio</Link>
          </div>
        </div>
      </AnimationOnScroll>
      <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal modal-main-form modal">
        <i className='icon-close' onClick={() => closeSendfrndformModal()}></i>

        <ModalTiny.Body className="people-details-form-modal event-section">
          <h2>Contact {person.name}</h2>
          <p>For more information, please leave a few details below and a member of our team will be in contact soon.</p>
          <IndividualContact to_email_id={person.email} person_name={person.email} />
        </ModalTiny.Body>

      </ModalTiny>
    </div>
  )
}

export default ManagerPanel