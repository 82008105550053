import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import rating from '../../images/property-img.png';
import ratingStars from '../../images/rating.png';
import { AppendScript } from "../../common/site/utils"

import './RatingSection.scss';

const RatingSection = () => {
    // useEffect(() => {
    //     AppendScript("https://apps.elfsight.com/p/platform.js", "elfsight");
    // })

    return (
        <section className='rating-wrap'>
            <Container>
               <h2 className='rating-wrap-title'>We are one of the Most Trusted Real Estate Brokers in Dubai</h2>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className='rating-content'>
                                <div className='rating-image flex-shrink-0'>
                                    <img src={rating} alt="rating" />
                                </div>
                                <div className='rating-text'>
                                    {/* <div className='rating-stars'>
                                        <img src={ratingStars} alt="rating-stars" />
                                    </div>
                                    <p>“Before working with Arabian Estates, I was having trouble getting my property noticed. Within just a few days of uploading my property on the site.”</p>
                                    <span className='name-wrap'>Joan McCarey – Sellerggggggggggg</span> */}
                                    <div className="elfsight-app-bb9cadc3-3b6c-43aa-891e-343c9c6ef207"></div>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default RatingSection
