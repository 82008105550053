import _ from "lodash"
import React from "react"
import { Link } from "gatsby"
import { Accordion, Container, Row, Col } from "react-bootstrap"
import { CONTACT_PAGE_URL } from '../common/site/constants'
import '../AreaContent/AreaContent.scss'
import "./GuidePoints.scss"
import parse from "html-react-parser"
import ContactDetails from '../ContactDetails/ContactDetails'
import RecentNews from '../RecentNews/RecentNewsAreaGuides'
import { AllNews } from '../../common/queries/common_use_query';

const GuidePoints = (props) => {

    const { loading: news_loading, error: news_error, data: news_data } = AllNews();

    return (
        <div className="guide-points-module section-p area-content">
            <Container>
                <Row>
                    <Col>
                        <div className="main-wrapper">
                            <div className="lft-content points-container">
                                {!_.isEmpty(props.Add_Content) && (
                                    <div className="point-section">
                                        {props.Add_Content?.map((data, i) => (
                                            <div className="guide row">
                                                <div className="bullet col-2">{i + 1}</div>
                                                <div className="content col-9 col-md-10">
                                                    <div className="title">
                                                        <h2>{data.Title}</h2>
                                                    </div>
                                                    {parse(data.Content)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className={`${props.layout === "guide_template" ? "d-xl-block d-none" : ""} rgt-content`}>
                                <div className='contact-box d-xl-block'>
                                    <ContactDetails />
                                </div>
                                <div className='news-box'>
                                    <div className='recent-news'>
                                        <h4>Recent News</h4>
                                        <RecentNews {...news_data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GuidePoints