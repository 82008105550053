
import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import { getSrc } from "gatsby-plugin-image"
import Review from "../../images/google-reviews.svg"
import bannerImg from "../../images/banner-image.svg"
import './Banner.scss';
import Tabbing from "../Tabbing/Tabbing";
import BannerSelect from "../BannerSelect/BannerSelect";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GenerateLink from "../../common/link/generate-link"
import ReviewBadge from "../ReviewBadge/ReviewBadge";

const LandingBanner = (props) => {
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    const tabbingData = {
        'Buy':
        {
            location: ["Location", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Rent':
        {
            location: ["Location 2", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Off Plan':
        {
            location: ["Location 3", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Holiday Lets':
        {
            location: ["Location 4", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'list a property':
        {
            location: ["Location 5", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
    }

    const landingPageArray = ["Buy", "Renting", "Selling", "Landlords", "Property Management", "Conveyancing"]
    let isLanding = landingPageArray.includes(props.title)

    const src = getSrc(props?.banner_image?.url_sharp.childImageSharp)

    return (
        <>
            <section className={`${scroll ? "banner--fixed" : ""} ${isLanding ? "landing_banner" : ""} banner`} style={{ backgroundImage: `url(${src})` }}>
            <div className='bg-overlay'></div>
                <Container>
                    <Row>
                        <Col lg={8}>
                            <div className="banner-wrapper bottom-fix">
                                <div className="rtl-wrapper">
                                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                        {parse(props.banner_content)}

                                        {props.show_reviews ?
                                            <div className="google-review">
                                                <ReviewBadge />
                                            </div>
                                            // <div className="google-review">
                                            //     <div className="review-img">
                                            //         <img src={Review} alt="review-img" />
                                            //     </div>
                                            //     <span className="review-text d-md-none">4.5+ from 243 Reviews</span>
                                            //     <span className="review-text d-none d-md-block">Rated 4.5+ from over 200 Reviews</span>
                                            // </div>
                                            : ''}
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            {props.show_search &&
                                <div className="banner-wrapper ">
                                    <div className="rtl-wrapper">
                                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                            {props.show_search ?
                                                <React.Fragment>
                                                    <div className="d-none d-md-block">
                                                        <Tabbing landingpage={true} tabbingData={tabbingData} />
                                                    </div>
                                                    <div className="d-md-none">
                                                        <BannerSelect landingpage={true} tabbingData={tabbingData} />
                                                    </div>
                                                </React.Fragment> : ''}
                                        </AnimationOnScroll>
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col lg={4}>
                            <div className="banner-wrapper land-banner-right">
                                <div className="rtl-wrapper">
                                    {props.banner_right_content &&
                                        <>{parse(props.banner_right_content)}</>}
                                    {props.banner_cta_link &&
                                        <GenerateLink class="btn" link={props.banner_cta_link}>{props.banner_cta_label}</GenerateLink>}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
}



export default LandingBanner