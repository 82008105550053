
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function AboutStructuredData({ description, pageurl }) {
  const site_url = process.env.GATSBY_SITE_URL; 
  var ldJson = {
      "@context": "http://schema.org",
      "@type": "RealEstateAgent",
      "Name": "Arabian Estates Real Estate",
      "URL": `${site_url}/about/`,
      "Image": "https://ggfx-arabianestates.s3.eu-west-2.amazonaws.com/i.prod/about_us_Arabian_Esatates_c1bcfc5a98.png",
      "Logo": "https://ggfx-arabianestates.s3.eu-west-2.amazonaws.com/i.prod/logo_black_67f0206787.png"
  }
    
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default AboutStructuredData
