import React, { useState, useEffect } from "react";
import parse from "html-react-parser"
import { Col, Container, Row } from "react-bootstrap";
import Review from "../../images/google-reviews-black.svg"
import '../NewsBanner/NewsBanner.scss'
import './StaticComponent.scss'
import ReviewBadge from "../ReviewBadge/ReviewBadge";
import ContactForm from "../forms/contact-form";

const StaticComponent = (props) => {

    const showContactform = props.alias === 'hoxton-capital'
    return (
        <>
        <section className='news-banner static-main-head'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            {/* <h4>{props.title}</h4> */}
                            {props.alias !== 'hoxton-capital' && <h4>{props.title}</h4>}
                            {props.content &&
                                <>
                                    {parse(props.content)}
                                </>
                            }
                            {props.show_reviews &&
                                <div className="google-review">
                                    {/* <div class={props.alias == "about-us" ? "elfsight-app-61eae175-93a9-4164-845c-569d6be9d0a0" : "elfsight-app-a80317a4-be89-40bf-a8f8-dd05a45a578b"}></div> */}
                                    <ReviewBadge isBlackText={props.alias === "about-us"} />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
        {showContactform && (
                <section className="banner banner--fixed" style={{
                    backgroundImage: `url(${props?.banner_image?.url})`,
                    paddingTop: '20px'
                }}>

                <div className='bg-overlay'></div>
                <Container>
                    <div className="form-full-image-page-static">
                        <section className="banner" style={{ padding: '0px' }}>
                            <div className="bg-overlay">
                                <div className="content">
                                    <div className="contact-form">
                                        <div className="head">
                                            {parse(props.add_components[0].contact_form_intro)}
                                        </div>
                                        <ContactForm to_email_id="nils@arabianestates.ae,info@arabianestates.ae,lucy.dricoll@hoxtoncapital.com"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>                   
                </Container>
            </section>
        )}
        </>
    )
}

export default StaticComponent