import * as React from 'react'
import Slider from "react-slick";
import { Container, Row, Col } from 'react-bootstrap'
import VideoCard from '../VideoCard/VideoCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img01 from "../../images/video-img.png";
import PlayVideo from "../Play/PlayVideo";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './VideoSlider.scss';

const VideosSlider = (props) => {
    let [isPlay, setPlay] = React.useState(false);
    let [videoId, setVideoId] = React.useState("");
    const VideoList = [
        {
            videoImg: Img01,
            title: "First Time Buyer",
            name: "Jerermy Wilkinson",
            videoId: "V8ksSGxo6no"
        },
        {
            videoImg: Img01,
            title: "Landlord",
            name: "Alex Smith",
            videoId: "V8ksSGxo6no"
        },
        {
            videoImg: Img01,
            title: "Holiday Lets",
            name: "Erica Worthington",
            videoId: "V8ksSGxo6no"
        },
    ];
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <React.Fragment>
            <section className='videos-slider'>
                <Container>
                    <Row>
                        <Col>
                            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                <div className='slider-content'>
                                    <Slider {...settings}>
                                        {props.add_video.map((video, index) => (
                                            <VideoCard key={index} VideoContent={video} setPlay={setPlay} setVideoId={setVideoId} />
                                        ))}
                                    </Slider>
                                </div>
                            </AnimationOnScroll>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default VideosSlider