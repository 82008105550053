import React from "react"
import "./ReviewBadge.scss"

const ReviewBadge = ({ isBlackText }) => {
  let className = isBlackText ? "black-review-badge" : "white-review-badge"
  return (
    <div className={className}>
      <div className={isBlackText ? "elfsight-app-162ab276-5785-4c65-b047-d38a3d71ea47" : "elfsight-app-a80317a4-be89-40bf-a8f8-dd05a45a578b"}></div>
    </div>
  )
}

export default ReviewBadge
