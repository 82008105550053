import { Link } from 'gatsby'
import React from 'react'
import '../ManagerPanel/ManagerPanel.scss'
import ManagerImg from "../../images/manager-img.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { AreaguideBaseURL } from "../../common/site/utils"
import GenerateLink from "../../common/link/generate-link"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const ManagerPanelTiles = (props) => {
  const item = props.item

  return (
    <div className='property-card manager-data'>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <GenerateLink link={item.Link} class="view-details">
          <div className='property-img'>

            {item.Tile_Image ?
              <img src={item.Tile_Image.url} alt={item.Tile_Title} />
            : <img src={ManagerImg} alt="no-image" /> }
          </div>
        </GenerateLink>
        <div className='property-content'>
          <h4>{item.Tile_Title}</h4>
          <div className='manager-details'>
            <GenerateLink link={item.Link} class="view-details">{item.Tile_Link_Label}</GenerateLink>
            {/* <Link to={`${AreaguideBaseURL}${item.slug}`} className='view-details'>View Guide</Link> */}
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default ManagerPanelTiles