import * as React from "react";
import PlayVideo from "../Play/PlayVideo";
import './VideoCard.scss';
const VideoCard = ({setPlay,VideoContent,setVideoId}) => {
    const video = VideoContent;
    const handleClick = (item) =>{
        setVideoId(item)
        setPlay(true)
    }
    return (
        <>
            <div className='video-card'>
                <div className='video-image'>
                    <img src={video.image.url} alt="video-img" />
                    {video.video_url &&
                        <PlayVideo url={video.video_url}/>
                    }
                </div>
                <div className='video-text'>
                    <h4>{video.title}</h4>
                    <h5>{video.content}</h5>
                </div>
            </div>
        </>
    )
}

export default VideoCard