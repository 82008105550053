import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import parse from "html-react-parser"
import { AnimationOnScroll } from "react-animation-on-scroll"
import "../BrowseProperty/BrowseProperty.scss"
import "./StaticComponent.scss"
import PlayVideo from "../Play/PlayVideo"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import GenerateLink from "../../common/link/generate-link"

const ImgContent = props => {
  const { media_block, text_block, Button_CTA, layout_type } = props

  let className
  if (layout_type === "right_align_image") {
    className = "img-right"
  } else {
    className = ""
  }
  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.text_with_image_Transforms) {
    processedImages = props.imagetransforms.text_with_image_Transforms;
  }
  return (
    <>
      <section className={`static-content-with-image ${className}`}>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col xl={6} lg={12}>
                <div className="img-section">
                <ImageTransform imagesources={media_block.media_file.url} renderer="srcSet" imagename={props?.title === "About Us" ? "pages.text_with_image.Media_block_about" : "pages.text_with_image.Media_block"} attr={{ alt: 'Content image - Arabian Estates', class: '' }} imagetransformresult={processedImages} id={props.id} format="source" />
                  {/* <img src={media_block.media_file.url} alt="Content-image" /> */}
                  {media_block?.Video_Url && (
                    <PlayVideo url={media_block.Video_Url} />
                  )}
                </div>
              </Col>
              <Col xl={6} lg={12}>
                <div className="content">
                  {parse(text_block.description)}
                  {Button_CTA &&
                  <GenerateLink class="btn" link={Button_CTA?.menu_item}>
                    {Button_CTA?.label}
                  </GenerateLink>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </AnimationOnScroll>
      </section>
    </>
  )
}

export default ImgContent
