import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Select from "react-select"
import { Tab, Tabs } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import ManagerImg from "../../images/manager-img.png"
import "../SearchData/SearchData.scss"
import "../SearchSelect/SearchSelect.scss"
import "./Team.scss"
import ManagerPanel from "../ManagerPanel/ManagerPanel"
import Pagination from "../Pagination/Pagination"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"

const allCategoryOption = [{label: "All Teams",value: "all"}];

const SearchData = () => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
          Team_Listing_Page_Order(sort:"order:ASC", where: { publish: true }) {
            id
            email
            name
            designation
            mobile_no
            category
            slug
            tile_image {
              alternativeText
              url
            }
            imagetransforms
          }
        }
      }
    }
  `)

  const allpeoples = data.glstrapi.globalModule.Team_Listing_Page_Order

  const [selectedPeoples, setSelectedPeoples] = useState(allpeoples)
  const [selectedOption, setSelectedOption] = useState(allCategoryOption[0])
  const { isMobile, isTablet } = useDeviceMedia()

  useEffect(() => {setCurrentPage(1)}, [selectedPeoples])

  let fetchCategory = allCategoryOption;
  if(allpeoples.length > 0){
    fetchCategory = allpeoples.map(data => data?.category);
  }
  const filterCategory = fetchCategory && [...new Set(fetchCategory)];
  const allCategory = filterCategory?.map((data => data && data.length > 0 && ({ label: data?.split('_').join(' '), value: data })));
  const selectOptions = allCategory ? [...allCategoryOption, ...allCategory] : allCategoryOption;

  // Category tab logic

  const handleTabChange = option => {
    setSelectedOption(option)
    if (option.value === "all") {
      setSelectedPeoples(allpeoples)
      return
    }
    const filteredPeoples = allpeoples.filter(people => people.category === option.value)
    setSelectedPeoples(filteredPeoples)
  }

  // for pagination
  const itemsPerPage = isMobile ? 8 : isTablet ? 12 : 16
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedPeoples,
    itemsPerPage,
  })

  return (
    <section className={"search-banner our-team-lisiting"}>
      <Container>
        <Row className="result-column management-wrap team-result news-tabbing">
          <Col md={12}>
            <div className="select-boxes d-block d-md-none">
              <div className="select-tabbing">
                <div className="select-wrap">
                  <Select
                    options={selectOptions}
                    isSearchable={false}
                    className={"react-select"}
                    classNamePrefix={"react-select"}
                    value={selectedOption}
                    onChange={handleTabChange}
                  />
                </div>
              </div>
            </div>

            <ul className="nav nav-tabs team-nav-tabs d-md-flex d-none">
              {selectOptions.map(data => {
                return (
                  <li className="nav-item">
                    <button
                      type="button"
                      className={`nav-link ${
                        selectedOption.value == data.value ? "active" : ""
                      }`}
                      onClick={() => handleTabChange(data)}
                    >
                      {data.label}
                    </button>
                  </li>
                )
              })}
            </ul>
          </Col>

          {currentItems?.map((item, index) => (
            <Col md={6} lg={3}>
              <ManagerPanel item={item} key={index} />
            </Col>
          ))}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={selectedPeoples.length}
            setCurrentPage={setCurrentPage}
            topValue={950}
            mobileTopValue={600}
          />
        </Row>
      </Container>
    </section>
  )
}

export default SearchData
