import React from 'react'
import { Link } from "gatsby"
import { ShowProcessedImage } from "../../common/ggfx-client/module/components/show-image"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import imageConfig from "../../../static/images/config.json"
import './PropertySlider.scss'

const PropertySlider = (props) => {
  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.images_Transforms) {
    processedImages = props.imagetransforms.images_Transforms;
  }
  return (
    <div className='property-card'>
      <Link to={props.propertyDetailsLink}>
        <div className='property-img'>
          {props.propertyImages && props.propertyImages.length > 0 ?
            <ImageTransform imagesources={props.propertyImages[0]?.url} renderer="srcSet" imagename="property.images.tile" attr={{ alt: `${props?.title} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={props.id} format="source" />
            // <img src={props.propertyImages[0].url} alt={props.location} /> 
            : <img src={props.thumb} alt="card-img" />}
        </div>
        <div className='property-content'>
          <div className='head-wrap'>
            <h4 className='item-ares'>{"AED " + props.price}</h4>
          </div>
          <h3>{props.location}
          </h3>
          <p>{props.title}</p>
          {
            props.UnderOffer && <span className='offer-tag'>Under Offer</span>
          }
        </div>
      </Link>
    </div >
  )
}

export default PropertySlider