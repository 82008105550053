import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../BrowseProperty/BrowseProperty.scss';
import './StaticComponent.scss'

import GenerateLink from "../../common/link/generate-link"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
const ImgContent = (props) => {

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.text_with_image_blue_Transforms) {
      processedImages = props.imagetransforms.text_with_image_blue_Transforms;
    }

    return (
        <section className='static-content-with-image blue-box'>
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                <Container>
                    <Row>
                        <Col md={4}>
                            {/* <img src={props.media_block.media_file.url} alt={props.text_block.title} /> */}
                            <ImageTransform imagesources={props.media_block.media_file.url} renderer="srcSet" imagename="pages.text_with_image_blue.Media_block_blue" attr={{ alt: 'Content image - Arabian Estates', class: '' }} imagetransformresult={processedImages} id={props.id} format="source" />
                        </Col>
                        <Col md={8}>
                            <div className='content'>
                                {props.text_block.title ? <h2>{props.text_block.title}</h2>
                                : ''}
                                {parse(props.text_block.description)}
                                {props.Button_CTA && props.Button_CTA?.Secondary_Url !== "#enquiry-form-section" ?
                                <GenerateLink class="btn" link={props.Button_CTA?.menu_item}>{props.Button_CTA?.label}</GenerateLink> :
                                <a class="btn samepage-form-link" href={props.Button_CTA?.Secondary_Url}>{props.Button_CTA?.label}</a>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </AnimationOnScroll>
        </section>
    )
}

export default ImgContent
