import React from "react"
import ReactSelect from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./Pagination.scss"
import $ from "jquery"

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
  topValue,
  mobileTopValue,
}) => {
  const { isMobile } = useDeviceMedia()

  const pageNumbers = []
  const pageNumberOptions = []

  // Generate page numbers
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
    pageNumberOptions.push({ value: i, label: i })
  }

  const totalPageNumbers = pageNumbers?.length

  const handlePageChange = value => {
    // window.scrollTo({ top: 0, behavior: "smooth" })
    $("html, body").animate(
      {
        scrollTop: isMobile
          ? mobileTopValue
            ? mobileTopValue
            : 0
          : topValue
          ? topValue
          : 0,
      },
      200
    )

    // handle change for dropdown select
    if (typeof value === "object") setCurrentPage(value.value)
    // handle change for number based page change
    else if (typeof value === "number") setCurrentPage(value)
    else
      switch (value) {
        case "back":
          setCurrentPage(page => --page)
          break
        case "next":
          setCurrentPage(page => ++page)
          break
        default:
          break
      }
  }

  if (totalPageNumbers < 2) return null

  return (
    <nav className="pagination-wrapper">
      <div className="pagination-container">
        <button
          onClick={() => handlePageChange("back")}
          className={`button button-secondary pagination-button button-back ${
            currentPage <= 1 ? "button-disabled" : ""
          }`}
          disabled={currentPage <= 1}
        >
          <i className="icon icon-arrow-left"></i>
          {!isMobile && <span>Previous</span>}
        </button>

        {isMobile ? (
          <div className="pagination-select-wrap">
            {/* <span className="page-text">Page:</span> */}
            <ReactSelect
              options={pageNumberOptions}
              isSearchable={false}
              className={"pagination-select"}
              classNamePrefix={"pagination-select"}
              value={{ value: currentPage, label: currentPage }}
              onChange={handlePageChange}
            />
            <span className="page-text">of {totalPageNumbers}</span>
          </div>
        ) : (
          <div className="pagination-numbers-wrap">
            {pageNumbers.map(number => (
              <button
                onClick={() => handlePageChange(number)}
                className={`button button-secondary pagination-button ${
                  currentPage === number ? "button-active" : ""
                }`}
              >
                <span>{number}</span>
              </button>
            ))}
          </div>
        )}

        <button
          onClick={() => handlePageChange("next")}
          className={`button button-secondary pagination-button button-next ${
            currentPage >= totalPageNumbers ? "button-disabled" : ""
          }`}
          disabled={currentPage >= totalPageNumbers}
        >
          {!isMobile && <span>Next</span>}
          <i className="icon icon-arrow-right"></i>
        </button>
      </div>
    </nav>
  )
}

export default Pagination
