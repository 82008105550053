import _ from "lodash"
import React from "react"
import { Link } from "gatsby"
import { Accordion, Container, Row, Col } from "react-bootstrap"
import { CONTACT_PAGE_URL } from '../common/site/constants'
import "./FAQModule.scss"
import parse from "html-react-parser"

const FAQModule = (props) => {
  return (
    <div className="faq-module-wrapper section-p">
      <Container className="faq-module-container">
        <Row>
            <Col md={4}>
                {parse(props.faq_intro)}
                <Link to={CONTACT_PAGE_URL.alias} className="btn btn-secondary ask-cta">Ask a Question</Link>
            </Col>
            <Col md={8}>
                {!_.isEmpty(props.choose_faqs) && (
                <div className="faq-section">
                    <Accordion flush>
                    {props.choose_faqs?.map((accordion, i) => (
                        <Accordion.Item eventKey={i} key={"acoordian" + i}>
                        <Accordion.Header>
                            <h6>{accordion.Question}</h6>
                        </Accordion.Header>
                        <Accordion.Body>{parse(accordion.Answer)}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                    </Accordion>
                </div>
                )}
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FAQModule