import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from "gatsby"
import PropertySlider from '../PropertySlider/PropertySliderHome'
import { Container, Row, Col } from 'react-bootstrap'
import './FindProperty.scss'
import PropertyOne from "../../images/property-one.png"
import PropertyTwo from "../../images/property-two.png"
import PropertyThree from "../../images/property-three.png"
import PropertyFour from "../../images/property-four.png"
import Select from 'react-select'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { numberFormat } from '../../common/site/utils'

const FindProperty = () => {


    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                item1: properties(
                    publicationState: LIVE
                    sort: "createdAt:desc"
                    limit: 4
                    where: {status: "for sale"}
                  ) {
                    price
                    display_address
                    title
                    imagetransforms
                    images
                    id
                    slug
                    search_type
                    department
                  }
                  item2:properties(
                    publicationState: LIVE
                    sort: "createdAt:desc"
                    limit: 4
                    where: {status: "to let"}
                  ) {
                    price
                    display_address
                    title
                    imagetransforms
                    images
                    id
                    slug
                    search_type
                    department
                  }
            }
        }
    `);


    const properties = data.glstrapi;
// console.log(properties)

    const [state, setState] = useState("property for sale")
    const tabbingData = {
        "property for sale": properties.item1,
        "property to rent": properties.item2,
        // "Off Plan Projects": [
        //     {
        //         propertyImg: PropertyOne,
        //         area: 'AED 770,000',
        //         title: 'Warda Apartments 1A, Town Square',
        //         detail: '2 bed apartment for sale'
        //     },
        //     {
        //         propertyImg: PropertyTwo,
        //         area: 'AED 1,750,000',
        //         title: 'Lofts West, Downtown Dubai',
        //         detail: '1 bed apartment for sale',
        //         UnderOffer: true,
        //     },
        //     {
        //         propertyImg: PropertyThree,
        //         area: 'AED 950,000',
        //         title: 'Zahra Breeze Apartments 3B, Town Square',
        //         detail: '2 bed apartment for sale'
        //     },
        //     {
        //         propertyImg: PropertyFour,
        //         area: 'AED 1,000,000',
        //         title: 'Icon 1, Jumeirah Lake Towers Town Square',
        //         detail: '2 bed apartment for sale'
        //     },
        // ],

    }
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    let tabOptions = []
    Object.keys(tabbingData)?.forEach((item, index) => {
        var main_data = tabbingData[item]
        if (main_data?.length > 0) {
            tabOptions.push({ value: item, label: item })
        }
    })

    return (
        <section className='find-property'>
            <Container>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className='property-wrapper'>
                                <h2>Find your next property in Dubai</h2>
                                <div className='d-none d-md-block'>
                                    <Tabs activeKey={state} onSelect={(k) => setState(k)}>
                                        {
                                            Object.keys(tabbingData).map((data, index) => {
                                                var main_data = tabbingData[data]
                                                return (
                                                    <Tab key={index} eventKey={data} title={main_data.length > 0 ? data : ''} className={`${main_data.length > 0 ? '' : 'd-none'}`}>
                                                        <Slider {...settings}>
                                                            {
                                                                tabbingData[data].map((item, index) => {
                                                                    let uriStr = "";
                                                                    let mysale = "";

                                                                    if (item.search_type === "sales" && item.department === 'residential') {
                                                                        uriStr = `property-for-sale/`
                                                                        mysale = `myResSaleProp`
                                                                    } else if (item.search_type === "lettings" && item.department === 'residential') {
                                                                        uriStr = `property-to-rent/`
                                                                        mysale = `myResRentProp`
                                                                    }
                                                                    return <PropertySlider price={item.price && item.price != null ? numberFormat(item.price) : ''} title={item.title} 
                                                                        location={item.display_address} propertyImages={item.images} propertyDetailsLink={`/${uriStr}${item.slug}-${item.id}/`} imagetransforms={item.imagetransforms} id={item.id}/>
                                                                })
                                                            }
                                                        </Slider>
                                                    </Tab>
                                                )
                                            })
                                        }
                                    </Tabs>
                                    {/* Pass active tab state to Buttondynamic */}
                                    <Buttondynamic activeTab={state} />
                                </div>
                                
                                <div className='d-md-none'>
                                    <div className='select-content'>
                                        <div className='select-wrapper'>
                                            <Select className="react-select" classNamePrefix="react-select" options={tabOptions} defaultValue={tabOptions[0]} onChange={(e) => setState(e.value)} isSearchable={false} />
                                        </div>
                                    </div>
                                    <Slider {...settings}>
                                        {
                                            tabbingData[state].map((item, index) => {
                                                let uriStr = "";
                                                let mysale = "";

                                                if (item.search_type === "sales" && item.department === 'residential') {
                                                    uriStr = `property-for-sale/`
                                                    mysale = `myResSaleProp`
                                                } else if (item.search_type === "lettings" && item.department === 'residential') {
                                                    uriStr = `property-to-rent/`
                                                    mysale = `myResRentProp`
                                                }
                                                return <PropertySlider price={item.price && item.price != null ? numberFormat(item.price) : ''} title={item.title}
                                                    location={item.display_address} propertyImages={item.images} propertyDetailsLink={`/${uriStr}${item.slug}-${item.id}/`} imagetransforms={item.imagetransforms}  id={item.id}/>
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}


const Buttondynamic = ({ activeTab }) => {
    let btnlink = "";

    if (activeTab === "property for sale") {
        btnlink = "/property/for-sale/in-dubai/";
    } else if (activeTab === "property to rent") {
        btnlink = "/property/to-rent/in-dubai/";
    }

    return (
        <div class="browse-more-properties" style={{ display: 'flex', justifyContent: 'center' }}>
            <a class="btn btn-primary" href={btnlink} >Browse More Properties</a>
        </div>
    );
};

export default FindProperty;
