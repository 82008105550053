
import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import { getSrc } from "gatsby-plugin-image"
import Review from "../../images/google-reviews.svg"
import bannerImg from "../../images/banner-image.svg"
import './Banner.scss';
import Tabbing from "../Tabbing/Tabbing";
import BannerSelect from "../BannerSelect/BannerSelect";
import GuideNav from "../PageNav/GuidePageNav"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GenerateLink from "../../common/link/generate-link"

const LandingBanner = (props) => {
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    const tabbingData = {
        'Buy':
        {
            location: ["Location", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Rent':
        {
            location: ["Location 2", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Off Plan':
        {
            location: ["Location 3", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'Holiday Lets':
        {
            location: ["Location 4", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
        'list a property':
        {
            location: ["Location 5", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            propertyType: ["Property Type", "Rent", "Off Plan", "Holiday Lets", "list a property"],
            maxPrice: ["Maximum Price", "Rent", "Off Plan", "Holiday Lets", "list a property"],
        },
    }

    const src = getSrc(props?.banner_image?.url_sharp.childImageSharp)

    return (
        <>
            <section className={`area-banner guide-landing-banner ${props.show_reviews ? "review-show" : ''}`} style={{ backgroundImage: `url(${src})` }}>
                <div className='bg-overlay'></div>
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <div className='content'>
                                    <GuideNav page={props.title} menu={props.menu.id}/>
                                    <div className="banner-wrapper">
                                        <div className="rtl-wrapper">
                                            {parse(props.banner_content)}
                                        </div>
                                    </div>
                                    {props.show_search &&
                                        <div className="banner-wrapper ">
                                            <div className="rtl-wrapper">
                                                {/* <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}> */}
                                                    {props.show_search ?
                                                        <React.Fragment>
                                                            <div className="d-none d-md-block">
                                                                <Tabbing tabbingData={tabbingData} />
                                                            </div>
                                                            <div className="d-md-none">
                                                                <BannerSelect tabbingData={tabbingData} />
                                                            </div>
                                                        </React.Fragment> : ''}
                                                {/* </AnimationOnScroll> */}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col lg={4}>
                            </Col>

                        </Row>
                    </Container>
                
            </section>
        </>
    )
}



export default LandingBanner