
import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useStaticQuery, graphql } from "gatsby"
import ContactForm from "../forms/contact-form"


const FormTemplate = (props) => {
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
          Address
          Email
          Phone
          Whatsapp
          Opening_Hours
        }
      }
    }
  `);

    const global = data.glstrapi.globalModule;
    return (
        <section className={scroll ? "banner banner--fixed" : "banner"} style={{ backgroundImage: `url(${props?.banner_image?.url})` }}>
            <div className="bg-overlay">
                <Container className="content">
                    <Row>
                        <Col md={12} lg={8}>
                            <div className="form-block">
                                {parse(props.add_components[0].Intro)}
                                <div className="contact-details">
                                    <Row>
                                        <Col md={6}>
                                            <div className="detail phone">
                                                <label>Telephone</label>
                                                <div className="phone-div">
                                                    <i className="phone"></i>
                                                    <a href={`tel:${global.Phone}`}>{global.Phone}</a>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="detail whatsapp">
                                                <label>WhatsApp</label>
                                                <div className="whatsapp-div">
                                                    <i className="whatsapp"></i>
                                                    <a href={`https://wa.me/+${global.Whatsapp}`} target="_blank">{global.Whatsapp}</a>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="detail address">
                                                <label>Address</label>
                                                {parse(global.Address)}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="detail opening-hours">
                                                <label>Opening Hours</label>
                                                {parse(global.Opening_Hours)}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="contact-form">
                                <div className="head">
                                    {parse(props.add_components[0].contact_form_intro)}
                                </div>
                                <ContactForm />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>

    )
}

export default FormTemplate