import { Link } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './AboutSection.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GenerateLink from "../../common/link/generate-link"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const AboutSection = (props) => {
    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.text_with_image_Transforms) {
      processedImages = props.imagetransforms.text_with_image_Transforms;
    }
    return (
        <section className='about'>

            <Container>
                {props?.text_block?.Main_Section_Title &&
                  <h2 className='about-section-title'>{props?.text_block?.Main_Section_Title}</h2>}
                <Row>
                    <Col>
                        {props.text_block &&
                            <div className='about-wrap'>

                                <div className='about-content'>
                                    <h3>
                                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                            <span>
                                                {props.text_block.title}
                                            </span>
                                            {props?.text_block?.title_below_image?.url && (
                                              <ImageTransform imagesources={props.text_block.title_below_image.url} renderer="srcSet" imagename={"pages.text_with_image.Media_block"} attr={{ alt: 'Content image - Arabian Estates', class: '' }} imagetransformresult={processedImages} id={props.id} format="source" />
                                            )}
                                        </AnimationOnScroll>
                                    </h3>
                                    <div className='content-wrapper'>
                                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                            <div className='about-para'>
                                                {parse(props.text_block.description)}
                                            </div>
                                            <GenerateLink link={props.link.menu_item} class="btn">{props.link.label}</GenerateLink>
                                            {/* <Link to="/" className='btn'>Why choose us</Link> */}
                                        </AnimationOnScroll>
                                    </div>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default AboutSection
